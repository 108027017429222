import React, { Component, Fragment } from "react";
import i18n from "../../views/Pages/Login/i18n";

class Footer extends Component {
  render() {
    const d = new Date();
    
    return (
      <footer className="app-footer">
        {window.config.consul.CLIENT === "enloc-ssp" ? (
          <div className="menu-footer-menu-container">
            <ul id="footer-menu" className="menu">
              <li
                id="menu-item-1006"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1006"
              >
                <a
                  href="https://www.enloc.de/service/kundenservice"
                  target="_blanc"
                >
                  {i18n.t("footer.contact-us")}
                </a>
              </li>
              <div className="separator"></div>
              <li
                id="menu-item-1006"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1006"
              >
                <a href="https://www.enloc.de/datenschutz" target="_blanc">
                  {i18n.t("footer.privacy-policy")}
                </a>
              </li>
              <div className="separator"></div>
              <li
                id="menu-item-294"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-294"
              >
                <a href="https://www.enloc.de/impressum" target="_blanc">
                  {i18n.t("footer.termsAndConditions")}
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <Fragment>
            <span>
              MGrid {VERSION} ✈ &copy; {d.getFullYear()} Methodia LTD.
            </span>
            <span className="ml-auto"></span>
          </Fragment>
        )}
      </footer>
    );
  }
}

export default Footer;
