import React, { useGlobal } from "reactn";
import { withApollo, Query } from "react-apollo";
import { Col, Row } from "reactstrap";
import {cdnDownloadFormatterName} from "../../../../utils/Formatters";
import {
	ElexonDashboard,
	insertReportActionMutation,
} from "../../../../queries/Queries";
import { ResponsiveContainer } from "recharts";
import BootstrapTable from "react-bootstrap-table-next";
import Loading from "../../../../components/Loading";
import ErrorPage from "../../../../components/ErrorPage/ErrorPage";

const ReportActions = {
	Approve: "approve",
	Cancel: "cancel",
};

const ElexonInvoices = ({ client }) => {
	const [username, setUsername] = useGlobal('username');

	const onReportActionClick = (reportId, action) => {
		client.mutate({
			variables: { report_id: reportId, action: action, createdBy: username },
			mutation: insertReportActionMutation,
			refetchQueries: [{ query: ElexonDashboard }],
		});
	};

	// graphql query still returns the old values when recalculating
	// but we don't want to display them
	const adjValuesFormatter = (cell, row) =>
		row.status === "Recalculating" ? null : cell;

	// default sorting function still takes into account column values
	// even though they're hidden. this sorting function puts all rows
	// with status 'recalculating' at the bottom
	const adjValuesSortFunc = (a, b, order, dataField, rowA, rowB) => {
		if (rowA.status === "Recalculating") return 1;
		if (rowB.status === "Recalculating") return -1;
		if (order === "asc") return a - b;
		else return b - a;
	};

	return (
		<div>
			<Row className="subheader">
				<Col className="col-8">
					<h1>Invoices</h1>
				</Col>
			</Row>
			<Row className="dashboard-content my-3 p-3">
				<Col className="col-12">
					<Query query={ElexonDashboard}>
						{({
							loading,
							error,
							data: { elexon_dashboard_latest2: queryData },
						}) => {
							if (loading) return <Loading />;
							if (error) return <ErrorPage />;
							return (
								<ResponsiveContainer>
									<BootstrapTable
										headerClasses="custom-header-class"
                    					wrapperClasses="table-responsive"
										noDataIndication={() => (
											<div>No records in table for this date.</div>
										)}
										columns={[
											{
												dataField: "run_date",
												text: "Run Date",
												formatter: (cell) => new Date(cell).toLocaleString(),
												sort: true,
											},
											{
												dataField: "payment_date",
												text: "Payment Date",
												formatter: (cell) =>
													new Date(cell).toLocaleDateString(),
												sort: true,
											},
											{
												dataField: "nonsf_adjustment",
												text: "Total Payable Amount (Non-SF)",
												formatter: (cell, row) => adjValuesFormatter(cell, row),
												sort: true,
												sortFunc: (a, b, order, dataField, rowA, rowB) =>
													adjValuesSortFunc(a, b, order, dataField, rowA, rowB),
											},
											{
												dataField: "rcrc_adjustment",
												text: "RCRC Adjustment (SF)",
												formatter: (cell, row) => adjValuesFormatter(cell, row),
												sort: true,
												sortFunc: (a, b, order, dataField, rowA, rowB) =>
													adjValuesSortFunc(a, b, order, dataField, rowA, rowB),
											},
											{
												dataField: "cashout_adjustment",
												text: "Cashout Adjustment (SF)",
												formatter: (cell, row) => adjValuesFormatter(cell, row),
												sort: true,
												sortFunc: (a, b, order, dataField, rowA, rowB) =>
													adjValuesSortFunc(a, b, order, dataField, rowA, rowB),
											},
											{
												dataField: "hedge",
												text: "Hedge Humber",
												formatter: (cell, row) =>
													row.invoice.map((inv, i) => (
														<div key={inv.hedge} className="hedge-number">
															{inv.hedge !== null ? "YES" : "N/A"}
														</div>
													)),
											},
											{
												dataField: "invoice",
												text: "Invoice",
												formatter: (cell) =>
													cell.map((inv) => (
														<div key={inv.id} className="inv-link">
															{cdnDownloadFormatterName(inv.url, inv.name)}
														</div>
													)),
											},
											{
												dataField: "createdby",
												text: "Approved By",
												formatter: (cell, row) => (
													<div>
														{["Sending", "Sent"].includes(row.status) // only show email when approved
															? cell
															: ""}
													</div>
												),
												sort: true,
											},
											{
												dataField: "action",
												text: "Action",
												formatter: (cell, row) => {
													return row.status === "Review" ? (
														<Row className="justify-content-around">
															<button
																onClick={() =>
																	onReportActionClick(
																		row.id,
																		ReportActions.Approve
																	)
																}
															>
																<i className={"icon ion-android-send"}></i>
															</button>
															<button
																onClick={() =>
																	onReportActionClick(
																		row.id,
																		ReportActions.Cancel
																	)
																}
															>
																<i className={"icon ion-android-refresh"}></i>
															</button>
														</Row>
													) : null;
												},
											},
											{
												dataField: "status",
												text: "Status",
												sort: true,
											},
										]}
										data={queryData}
										keyField={"id"}
										loading={loading}
										bordered={false}
										hover
										remote={{
											filter: true,
											pagination: false,
											sort: false,
											cellEdit: false,
										}}
									/>
								</ResponsiveContainer>
							);
						}}
					</Query>
				</Col>
			</Row>
		</div>
	);
};

export default withApollo(ElexonInvoices);
