import React, {Component} from 'reactn';
import PaginatedQueryDataGrid from "../../components/PaginatedQueryDataGrid"
import {Button, Col, FormGroup, Row} from "reactstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import i18n from '../../views/Pages/Login/i18n';
import schemaUK from "../../schemas/task-dyce.schema.js"
import schemaDE from "../../schemas/task-enloc.schema.js"
import 'react-dates/lib/css/_datepicker.css';
import TasksComments from "../../components/TasksComments";
import {dateFormatter, queryTypeFotmatter, queryTypeFormatterDE, jsonataFormatter} from "../../utils/Formatters";
import {transformSchema} from '../../utils/Helpers';
import {Query} from "react-apollo";
import Loading from "../../components/Loading";
import gql from "graphql-tag";
import loadable from "@loadable/component";

const TasksDetail = loadable(() => {
    return import("./../../" + window.config.consul.CLIENT + "/components/TasksDetail/TasksDetail")
        .catch(() => import("./../../components/TasksDetail/TasksDetail")
            .catch(() => import("./../../views/Missing")))
}
)

const CreateTask = loadable(() => {
    return import("./../../" + window.config.consul.CLIENT + "/views/CreateTask/CreateTask")
        .catch(() => import("./../../views/CreateTask/CreateTask")
            .catch(() => import("./../../views/Missing")))
}
)

class Queries extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            showCreateTask: false,
            filters: {
                status: [{id: "Open", label: "Open"}, {id: "In Progress", label: "In Progress"}]
            },
            task: this.props.match && this.props.match.params && this.props.match.params.queryId ? this.props.match.params.queryId : null,
            page: 1,
            tasksPerPage: 10
        };
        this.getMeterPoints = this.getMeterPoints.bind(this);
    }

    toggle() {
        this.setState({
            showCreateTask: !this.state.showCreateTask
        });
    }

    getQueries() {
        const result = {};
        const uiQueries = this.props.configuration.uiQueries;
        for (let i = 0; i < uiQueries.length; i++) {
            result[uiQueries[i].name] = uiQueries[i].query;
        }
        return result;
    }

    getMeterPoints(customer) {
        return customer.flatMap(account => {
            return account.accounts.reduce((acc, properties) => {
                properties.properties.length > 0 &&
                acc.push(properties.properties.flatMap(site => site.site.meterPoints.flatMap(meterPoint => meterPoint.identifier)));

                return acc;
            }, []).flat();
        })
    }

    render() {
        const queries = this.getQueries();
        const includeContentFields = this.props.includeContentFields ? this.props.includeContentFields : null;
        const isEnloc = window.config.consul.CLIENT === "enloc-ssp";
        const schema = isEnloc ? schemaDE : schemaUK;

        return (
            <div className="manage-users tasks-page">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('task.title')}</h1>
                    </Col>
                    <Col className='col-4'>
                        {!this.props.hideQueryBtn && <FormGroup>
                            <Button onClick={() => this.setState({showCreateTask: true})} className='pull-right'
                                    color="primary"> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('newQueries.btn')}
                            </Button>
                        </FormGroup>}
                    </Col>
                </Row>
                <div className="page-cnt">
                    <Row>
                        <Col sm={12}>
                            <PaginatedQueryDataGrid export={true} dataPath={this.props.queryPath + '.content'}
                                                    queryKey={this.props.queryKey} queries={queries}
                                                    paginationPath={this.props.queryPath}
                                                    keyField={'id'}
                                                    note={this.props.note}
                                                    updateKey="listOfTasksQuery"
                                                    defaultSorted={{
                                                        dataField: 'createTime',
                                                        order: 'desc'
                                                    }}
                                                    gridRowClass={'tasks-cnt1 row-eq-height '}
                                                    gridColClass={'tasks-list'}
                                                    gridColSM={this.state.task ? 4 : 12}
                                                    children={this.state.task && <Col sm={8} className="task-details-cnt">
                                                        <TasksDetail
                                                            power={this.props.power}
                                                            task={this.state.task}
                                                            resetView={() => this.setState({task: null})} />
                                                        <TasksComments
                                                            task={this.state.task}/>
                                                    </Col>}
                                                    variables={this.props.variables ? this.props.variables : ''}
                                                    columns={[
                                                        {
                                                            dataField: 'variables.consumer_number',
                                                            text: i18n.t("taskGridHeader.accountNumber"),
                                                            sort: true
                                                        },
                                                        {
                                                            dataField: 'variables.mprn',
                                                            text: i18n.t("taskGridHeader.meterPoint"),
                                                            sort: true
                                                        },
                                                        ...(!this.state.task && [
                                                            {
                                                                dataField: 'variables.type',
                                                                text: i18n.t('taskGridHeader.type'),
                                                                sort: true,
                                                                formatter: function(cell, row) {
                                                                    const text = isEnloc ?
                                                                        queryTypeFormatterDE(cell, row, null, this.props) :
                                                                        queryTypeFotmatter(cell, row, null, this.props);
                                                                    return row.attachmentCount ? (
                                                                        <div><i className="icon ion-android-attach"
                                                                                title={"Attachments"}></i> {text}</div>) : text
                                                                }.bind(this),
                                                                csvformatter: function(cell, row) {
                                                                    const text = isEnloc ?
                                                                        queryTypeFormatterDE(cell, row, null, this.props) :
                                                                        queryTypeFotmatter(cell, row, null, this.props);
                                                                    return row.attachmentCount ? "📎 "+ text : text
                                                                }.bind(this)
                                                            },
                                                            {
                                                                dataField: 'createTime',
                                                                text: i18n.t('taskGridHeader.dateRaised'),
                                                                formatter: dateFormatter,
                                                                sort: true
                                                            },
                                                            ...(this.props.queryKey === "MyQueries" && this.props.admin 
                                                                ? [{
                                                                    dataField: 'variables.status',
                                                                    text: i18n.t('tasks.status'),
                                                                    dropdown: true,
                                                                    statuses: ["Open","Closed"],
                                                                    mutationKey: "updateTask",
                                                                    sort: true
                                                                }] : [{
                                                                    dataField: 'variables.status',
                                                                    text: i18n.t('tasks.status'),
                                                                    formatter: (cell) => i18n.t(`taskFilter.status${cell}`),
                                                                    sort: true
                                                                }]
                                                            )
                                                        ])
                                                    ]}
                                                    filters={[
                                                        ...(
                                                            this.props.queryKey === 'QueryByCustomer' && [{
                                                                type: "text",
                                                                fields: ['owner','variables.mprn', 'variables.account_code', 'variables.estate', 'variables.external_id', 'variables.consumer_number', 'description', 'owner'],
                                                                placeholder: `Search by Meter Point/Account Number/Site Name/Query ID/Queries Name`,
                                                                label: i18n.t('taskFilter.search'),
                                                                classes: "search",
                                                                cols: 4
                                                            }]
                                                        ),
                                                        ...(
                                                            this.props.queryKey === 'QueryByProperty' && [{
                                                                type: "text",
                                                                fields: ['owner','variables.external_id'],
                                                                placeholder: 'Search by Meter Point or Raised By',
                                                                label: i18n.t('taskFilter.search'),
                                                                classes: "search",
                                                                cols: 4
                                                            }]
                                                        ),
                                                        ...(
                                                            this.props.queryKey === 'MyQueries' && [{
                                                                type: "text",
                                                                fields: ["owner", "variables.mprn","variables.account_code","variables.consumer_number"],
                                                                placeholder: i18n.t(`taskFilter.searchPlaceholder`),
                                                                label: i18n.t('taskFilter.search'),
                                                                classes: "search",
                                                                cols: 4
                                                            }]
                                                        ),
                                                        ...(
                                                            this.props.queryKey && [{
                                                                type: "date",
                                                                fields: ["createTime"],
                                                                placeholder: i18n.t('taskFilter.date'),
                                                                label: i18n.t('taskFilter.date'),
                                                                cols: 4,
                                                                isLocale: true,
                                                                disableFutureDates: true
                                                            }]
                                                        ),
                                                        ...(
                                                            this.props.queryKey && [{
                                                                type: "typeahead",
                                                                fields: ["variables.status"],
                                                                possibleValues: [{key: 'Open', value: i18n.t('taskFilter.statusOpen')}, {key: 'Closed', value: i18n.t('taskFilter.statusClosed')}],
                                                                placeholder: i18n.t('tasks.status'),
                                                                label: i18n.t('tasks.status'),
                                                                classes: 'select'
                                                            }]
                                                        ),
                                                        ...(
                                                            this.props.queryKey && [{
                                                                type: "typeahead",
                                                                fields: ["variables.type"],
                                                                enumValues: this.props.power ? transformSchema(schema, 'E').properties.type.anyOf : transformSchema(schema, 'G').properties.type.anyOf,
                                                                placeholder: i18n.t('tasks.type'),
                                                                label: i18n.t('tasks.type'),
                                                                classes: 'select'
                                                            }]
                                                        ),
                                                    ]}
                                                    initialFilters={{'variables.status': "Open"}}
                                                    onSelect={(selected) => {
                                                        this.setState({task: selected})
                                                    }}
                            />
                        </Col>
                        <Query
                            // only run query once user clicks on btn
                            // to open modal
                            skip={!this.state.showCreateTask}
                            query={gql`
                                ${queries[this.props.secondQueryKey]}
                            `}
                            variables={{
                                pageSize: 100,
                                pageNumber: 0,
                                sort: {
                                    orders: [
                                        {
                                            property: "name",
                                            direction: "asc",
                                        },
                                    ],
                                },
                                ...(includeContentFields && includeContentFields),
                                where: null,
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <Loading />;
                                if (error) return `Error! ${error.message}`;

                                return data ? (
                                    <CreateTask
                                        modalOpen={this.state.showCreateTask}
                                        onClose={this.toggle}
                                        refetch={this.refetchGrid}
                                        user={this.global.username}
                                        properties={
                                            data.Customer.content.length > 0 &&
                                            data.Customer.content.flatMap((account) =>
                                                account.accounts.filter(
                                                    (properties) => properties.properties.length > 0
                                                )
                                            )
                                        }
                                        meterPointsData={
                                            data.Customer.content.length > 0 &&
                                            data.Customer.content.flatMap((account) =>
                                                account.accounts.flatMap((x) =>
                                                    x["properties"].flatMap((y) => y["site"].meterPoints)
                                                )
                                            )
                                        }
                                        accounts={
                                            data.Customer.content.length > 0 &&
                                            data.Customer.content.flatMap((account) =>
                                                account.accounts.flatMap((x) =>
                                                    isEnloc ? x.number : x["properties"].flatMap((consumerNumber) => consumerNumber.name)
                                                )
                                            )
                                        }
                                        />
                                ) : null;
                            }}
                        </Query>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Queries;
