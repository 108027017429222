import gql from "graphql-tag";

const getInvoiceQuery = gql`
    query invoiceQuery($where: String!, $orderBy: String!) {
        invoice(startRow:0, offset: 100, where: $where, orderBy: $orderBy) {
            id
            documentNo
            invoiceDate
            grandTotalAmount
            invoiceTaxList{
                taxAmount
                taxableAmount

            }
            invoiceDiscountList{
                discount{
                    discount
                }
            }
            currency{
                symbol
                iSOCode
            }
            billPeriod {
                id
                creationDate
                endingDate
                startingDate
            }
            fINPaymentScheduleList{
                dueDate
            }
            isPaymentComplete
            outstandingAmount

            noteList{
                id
                note
                creationDate
                updated
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
            attachmentList{
                id
                name
                creationDate
                updated
                text
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
        }
    }

`;

const getLastReadingDate = gql`
    query getLastReadingDate($pageNumber: Int $pageSize: Int $sort: SortInput $where: String) {
        Reading( page: { pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort } spec: { where: $where }) {
            content {
                date
            }
        }
    }
`;

const getLastInvoiceDate = gql`
    query getLastInvoiceDate($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
        Invoice(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, where: $where) {
            content {
                issueDate
            }
        }
    }
`

const getBalance = gql`
    query getBalance($id: String!){
        getBalance(bp_id:$id){
            balance
            customer
            details{
                balance
                date
                number
                total
                id
                currency
                currencyISOCode
                type
            }
            provider
        }
    }

`;
const getContractId = gql`
    query contractQuery {
        contract(startRow: 0, offset: 100, where: "", orderBy: "id") {
            id
        }
    }`;

const getNumberOfUnreadMessages = gql`
    query unreadMessagesQuery {
        UnreadNotificationCount
    }
`

const SingleNotification = gql`
    query SingleNotification($id: String!) {
        SingleNotification: SingleNotification(id: $id) {
            body
            type
            created
        }
    }

`

const getContractQuery = gql`
    query contractQuery($where: String!, $orderBy: String!) {
        contract(startRow: 0, offset: 100, where: $where, orderBy: $orderBy) {
            id
            documentnumber
            creationDate
            expirationdate
            signdate
            startDate
            tariff
            contractDurationInMonths
            mDMContractStatus{
                name
            }
            businessPartner{
                id
                name
                bILLBillingAccountList{
                    paymentMethod{
                        name
                    }
                    billingAccountNumber
                    currentStatus{
                        status
                    }
                }
            }
            noteList{
                id
                note
                creationDate
                updated
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
            attachmentList{
                id
                name
                creationDate
                updated
                text
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
        }
    }

`;

const mpanNames = gql`query MpanNames($where:String, $field:String) {
    Mpan (page: {pageSize: 100, pageNumber: 0, sort:{orders: [{property: $field, direction: "asc"}]}}, spec:{where: $where}) {
        content { 
            meterPoint {
                identifier
                name
            }
        }
    }
}`;

const Properties = gql`query Properties($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
  Property(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, where: $where) {
    content {
      id
      name
      address
      status
      startDate
      metadata
      parentProperty {
        id
      }
      account {
        number
        status
        balance {
          amount
        }
        customer {
          id
          name
          contracts {
            product
          }
        }
        organization {
          id
        }
      }
      site {
        id
        name
        address
        meterPoints {
          id
          identifier
          purpose
          supplyDate
          hasAmr
          amrScheduleCode
          hasCorrector
          name
          status
          supplyEndDate
          site {
            name
          }
          registers {
            id
            digits
            rate
            rateId
            effectiveTo
            readings {
              date
              type
              value
            }
            consumptions {
              presentReading {
                date
                type
                value
              }
            }
            meter {
              id
              serialNumber
              type
              isHH
            }
          }
        }
      }
    }
    size
    totalPages
    first
    last
    number
    numberOfElements
    size
    totalElements
    totalPages
    pageable {
      pageNumber
      pageSize
      paged
      offset
    }
  }
}
`;


const getBusinessPartnerShortQuery = gql`
    query businessPartner($where: String!, $orderBy: String!) {
        businessPartner(startRow: 0, offset: 100, where: $where, orderBy: $orderBy) {
            id
            isMdmIslegalentity
            mdmEik
            mdmPersonalno
            name
            businessPartnerCategory {
                id
                name
            }
            cRMBpartnerContactList {
                user {
                    email
                    phone
                    isMdmIsowner
                }
            }
            bILLBillingAccountList {
                billingAccountNumber
            }

            mDMBPEstateList {
                mDMEstate {
                    mDMMeterPointList{
                        name
                    }
                }
            }
        }
    }
`;

const getBusinessPartnerMiddleQuery = gql`
    query businessPartner($where: String!, $orderBy: String!) {
        businessPartner(startRow: 0, offset: 100, where: $where, orderBy: $orderBy) {
            id
            isMdmIslegalentity
            mdmEik
            mdmPersonalno
            name
            businessPartnerCategory {
                id
                name
            }
            cRMBpartnerContactList {
                user {
                    email
                    phone
                    isMdmIsowner
                }
            }
            bILLBillingAccountList {
                billingAccountNumber
            }
            mDMBPEstateList {
                id
                consumernumber
                mDMEstate {
                    id
                    locationAddress {
                        addressLine1
                    }
                    mDMMeterPointList{
                        id
                        name
                        mDMMeterRegisterList{
                            id
                            meterReadingDigitsCount
                            mDMRegister{
                                product{
                                    id
                                    name
                                }
                            }
                            meter{
                                id
                                serialNumber
                                ammMeterReadingList{
                                    id
                                    readingdate
                                    readStatus
                                    validationStatus
                                    newreadingmetervt
                                    customerReadType
                                    product {
                                        id
                                        searchKey
                                        mdmPurpose
                                        uOM {
                                            id
                                            name
                                        }
                                    }
                                    mDMMeterMeasurement {
                                        id
                                        reportedamount
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const brokerBalance = gql`
    query brokerBalance($specification: OB_SpecificationInputInput, $page: OB_PageInput, $businessPartnerId: String, $from: String, $to: String) {
        OB_brokerBalance(specification: $specification, page: $page, businessPartnerId: $businessPartnerId, from: $from, to: $to) {
            totalAmount
        }
    }
`

const getBusinessPartnerQuery = gql`
    query businessPartnerQuery($startRow: Int!, $offset: Int!, $where: String!, $orderBy: String!) {
        businessPartner(startRow: $startRow, offset: $offset, where: $where, orderBy: $orderBy) {
            id
            isActive
            isMdmIslegalentity
            mdmEik
            mdmPersonalno
            name
            mdmCompanyName
            mdmCompanyType
            mdmContragentno
            paymentMethod{
                name
            }
            currency{
                iSOCode
            }
            businessPartnerBankAccountList {
                id
                accountNo
                accountType
                iBAN
                bankName
                swiftCode
                isActive
                mdmPreferredPaymentDate
                tspayDdSetUpStatus
                userContact{
                    name
                    firstName
                    lastName
                }
                ukmAdditionalAccntHldr
                name
                mdmBankinfo{
                    name
                }
            }
            mdmContactAddress{
                locationAddress{
                    addressLine1
                }
            }
            mDMContractList{
                id
                documentnumber
                signdate
                expirationdate
                offerDate
                contractDurationInMonths
                mDMContractStatus{
                    name
                }
                billBillingPlanList{
                    amountWithTax
                }
                marketingProduct {
                    name
                    productBOMList {
                        bOMProduct {
                            name
                        }
                    }
                }

                noteList{
                    id
                    note
                    creationDate
                    updated
                    createdBy{
                        name
                    }
                    updatedBy{
                        name
                    }
                }
                attachmentList{
                    id
                    name
                    creationDate
                    updated
                    text
                    createdBy{
                        name
                    }
                    updatedBy{
                        name
                    }
                }
            }
            businessPartnerCategory {
                id
                name
            }
            cRMBpartnerContactList {
                user {
                    id
                    isMdmIsowner
                    firstName
                    lastName
                    birthday
                    email
                    phone
                    mdmTitle
                    mdmContactAddress{
                        locationAddress{
                            addressLine1
                        }
                    }
                }
            }
            mDMBPEstateList {
                id
                mDMEstate {
                    id
                    locationAddress {
                        addressLine1
                    }
                    mDMMeterPointList{
                        id
                        name
                        mDMMeterRegisterList{
                            id
                            meterReadingDigitsCount
                            mDMRegister{
                                product{
                                    id
                                    name
                                }
                            }
                            meter{
                                id
                                serialNumber
                                ammMeterReadingList{
                                    id
                                    readingdate
                                    readStatus
                                    validationStatus
                                    newreadingmetervt
                                    customerReadType
                                    product {
                                        id
                                        searchKey
                                        mdmPurpose
                                        uOM {
                                            id
                                            name
                                        }
                                    }
                                    mDMMeterMeasurement {
                                        id
                                        reportedamount
                                    }
                                }
                            }
                        }
                    }
                }
            }
            fINPaymentList {
                id
                organization {
                    name
                }
                documentType {
                    name
                }
                documentNo
                paymentDate
                paymentMethod {
                    name
                }
                status
                amount
                isBillRefunddepositBtn
                billContract {
                    documentnumber
                }
                tspayName
                posted
                isAprmExecutepayment
                billEstate {
                    name
                    consumernumber
                }
                usedCredit
                generatedCredit
                description
                currency {
                    iSOCode
                    symbol
                }
                referenceNo
                isBillForcedreverse
                billLicensingTerritory {
                    name
                }
                mdmTerritory
                isBillFinSysExported
            }
            bILLInvoicesPenaltiesVList {
                id
                organization {
                    name
                }
                totalPaid
                totalamount
                invoiceDate
                documentNo
                amount
                totalPaid
                invoice{
                    noteList{
                        id
                    }
                    attachmentList{
                        id
                    }
                }
                billingDocumentType {
                    documentName
                    documentType{
                        name
                    }
                }
                currency {
                    symbol
                    iSOCode
                }
                flowtype
                dueDate
                isLicensing
                isProform
                isPenalty
                isPaymentComplete
                estate {
                    name
                    consumernumber
                }

                isActive
                period {
                    id
                    creationDate
                    endingDate
                    startingDate
                }
                businessPartner {
                    id
                    name
                }
                collStatus
                noteList{
                    id
                    note
                    creationDate
                    updated
                    createdBy{
                        name
                    }
                    updatedBy{
                        name
                    }
                }
                attachmentList{
                    id
                    name
                    creationDate
                    updated
                    text
                    createdBy{
                        name
                    }
                    updatedBy{
                        name
                    }
                }
            }
            printDocumentList(startRow:0, offset: 100, where: "billDoctype.documentName = 'Customer Statement'", orderBy:"id"){
                creationDate
                json
                document
            }
            noteList{
                id
                note
                creationDate
                updated
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
            attachmentList{
                id
                name
                creationDate
                updated
                text
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
        }
    }
`;



const getUserQuery = gql`
    query GetUser($username:String){
        getUser(username:$username){
            username
            enabled
            firstName
            middleName
            lastName
            title
            jobTitle
            avatarUrl
            phoneNumbers{
                phoneNumberId
                phoneNumber
                type
                primaryNumber
            }
            organizations{
                organizationId
                name
                businessPartnerId
                avatarUrl
#                status{
#                    key
#                    name
#                    requireReason
#                }
                ultimateParent{
                    organizationId
                    name
                    businessPartnerId
                }
            }
            userSystems{
                userSystemNameId
                system{
                    systemName
                }
                systemUser
            }
            roles{
                role {
                    key
                    name
                    permissions{
                        permission{
                            key
                            name
                        }
                    }
                }
            }
        }
    }
`;

const getRolesForUserQuery = gql`
    query GetRolesForUser($username:String){
        getRolesForUser(username:$username)
    }
`;

const createUserMutation = gql`
    mutation CreateUser($user:com_methodia_cockpit_core_domain_UserInput){
        createUser(user: $user)
    }
`;

const updateUserMutation = gql`
    mutation UpdateUser($user:com_methodia_cockpit_core_domain_UserInput){
        updateUser(user:$user)
    }
`;


const deleteUserMutation = gql`
    mutation DeleteUser($username:String!){
        deleteUser(username:$username)
    }
`;


const unlockUserMutation = gql`
    mutation UnlockUser($username:String){
        unlockUser(username: $username)
    }
`;
const changePasswordMutation = gql`
    mutation ChangePassword($user:com_methodia_cockpit_core_domain_UserInput){
        changeUserPassword(user:$user)
    }
`;

const changeOwnPasswordMutation = gql`
    mutation ChangeOwnPassword($passwordHolder:com_methodia_cockpit_core_domain_PasswordHolderInput){
        changeOwnPassword(passwordHolder:$passwordHolder)
    }
`;

const createUserRoles = gql`
    mutation CreateUserRoles($username:String, $roles:[String]){
        createUserRoles(username: $username, roles:$roles)
    }
`;

const deleteUserRoles = gql`
    mutation DeleteUserRoles($username:String, $roles:[String]){
        deleteUserRoles(username: $username, roles:$roles)
    }
`;


const getUsers = gql`
    query GetUsers {
        getUsers{
            username
            enabled
            locked
            firstName
            jobTitle
            lastName
            title
            avatarUrl
            roles{
                role {
                    key
                    name
                    permissions{
                        permission{
                            key
                            name
                        }
                    }
                }
            }
            phoneNumbers{
                phoneNumber
                primaryNumber
            }
            organizations{
                businessPartnerId
                avatarUrl
                name
            }
        }
    }
`;

const getAllRoles = gql`
    query GetAllRoles {
        getAllRoles{
            key
            name
            permissions{
                permission{
                    key
                    name
                }
            }
        }
    }
`;
const getAllPermissions = gql`
    query GetAllPermissions {
        getAllPermissions{
            key
            name
        }
    }
`;


const createUpdateRolesMutation = gql`
    mutation CreateUpdateRoles($roles: [com_methodia_cockpit_core_domain_RoleInput]){
        createUpdateRoles(roles: $roles)
    }
`;

const createRolePermissionsMutation = gql`
    mutation CreateRolePermissions($rolePermissions: [com_methodia_cockpit_core_domain_RolePermissionInput]){
        createRolePermissions(rolePermissions: $rolePermissions)
    }
`;
const deleteRolePermissionsMutation = gql`
    mutation DeleteRolePermissions($rolePermissions: [com_methodia_cockpit_core_domain_RolePermissionInput]){
        deleteRolePermissions(rolePermissions: $rolePermissions)
    }
`;
const createNoteMutation = gql`
    mutation CreateNote($content: String!, $contextTableName: String!, $recordId: String!){
        createNote(content: $content, contextTableName: $contextTableName, recordId: $recordId)
    }
`;

const updateNoteMutation = gql`
    mutation UpdateNote($content: String!, $id: String!){
        updateNote(content: $content, id: $id)
    }
`;
const deleteNoteMutation = gql`
    mutation DeleteNote($id: String!){
        deleteNote(id: $id)
    }
`;

const uiConfigurationQuery = gql`
    query getUIConfiguration{
        getUIConfiguration{
            name
            uiMenus{
                uiMenuItemOrders{
                    order
                    defaultMenu
                    uiMenuItem{
                        name
                        defaultLabel
                        type
                        icon
                        url
                        badgeText
                        badgeVariant
                    }
                    children{
                        order
                        defaultMenu
                        uiMenuItem{
                            name
                            defaultLabel
                            type
                            icon
                            url
                            badgeText
                            badgeVariant
                        }
                        children{
                            order
                            defaultMenu
                            uiMenuItem{
                                name
                                defaultLabel
                                type
                                icon
                                url
                                badgeText
                                badgeVariant
                            }
                        }
                    }
                }

            }
            uiLayouts{
                id
                jsx
                name
            }
            uiQueries{
                id
                name
                query
                variables
            }
        }
    }
`;

const allUiConfigurationsQuery = gql`
    query getAllUIConfigurations{
        getAllUIConfigurations{
            id
            role
            uiConfiguration{
                id
                name
                uiMenus{
                    id
                    uiMenuItemOrders{
                        id
                        order
                        defaultMenu
                        uiMenuItem{
                            id
                            name
                            defaultLabel
                            type
                            icon
                            url
                            badgeText
                            badgeVariant
                        }
                        children{
                            id
                            order
                            defaultMenu
                            uiMenuItem{
                                name
                                defaultLabel
                                type
                                icon
                                url
                                badgeText
                                badgeVariant
                            }
                            children{
                                order
                                defaultMenu
                                uiMenuItem{
                                    name
                                    defaultLabel
                                    type
                                    icon
                                    url
                                    badgeText
                                    badgeVariant
                                }
                            }
                        }
                    }

                }
                uiLayouts{
                    id
                    jsx
                    name
                }
                uiQueries{
                    id
                    name
                    query
                    variables
                }
            }
        }
    }
`;

const allUiMenuItemsQuery = gql`
    query getAllUIMenuItems{
        getAllUIMenuItems{
            id
            name
            defaultLabel
            type
            icon
            url
            badgeText
            badgeVariant
        }
    }
`;

const organizationsQuery = gql`
    query organizations {
        getOrganizations{
            organizationId
            name
            businessPartnerId
            avatarUrl
#            status{
#                key
#                name
#                requireReason
#            }
            parent {
                name
                organizationId
            }
            ultimateParent {
                organizationId
            }
        }
    }
`;

const systemsQuery = gql`
    query systems {
        getSystems{
            systemName
            displayName
            url
        }
    }
`;
const updateSystemMutation = gql`
    mutation updateSystem($systemName: String, $url:String, $displayName: String){
        updateSystem(systemName: $systemName, url: $url, displayName: $displayName)
    }
`;

const createReadingMutation = gql`
    mutation CreateReading($bpEstateId: String!, $estateId: String!, $meterId: String!, $productId: [String]!, $readingDate: Date!, $readingType: String, $readingValue: [Long]!, $customerReadType: String, $process: String){
        createReading(bpEstateId: $bpEstateId,estateId: $estateId, meterId: $meterId, productId: $productId, readingDate: $readingDate, readingType: $readingType, customerReadType: $customerReadType, readingValue: $readingValue, process: $process)
    }
`;

const getTaskVariableQuery = gql`
    query getTaskVariable($taskId: String!, $variableName: String!){
        getTaskVariable(taskId: $taskId, variableName: $variableName)
    }
`;

const getTasksQuery = gql`
    query getTasks{
        getOwnTasks{
            id
            name
            description
            assignee
            owner
            priority
            dueDate
            variables{
                name
                type
                value
            }
            createTime
            service
        }
    }
`;
const createTaskMutation = gql`
    mutation createCamundaTask($task: com_methodia_cockpit_core_domain_TaskInput){
        createCamundaTask(task: $task)
    }
`;


const updateTaskMutation = gql`
    mutation updateTask($task: com_methodia_cockpit_core_domain_TaskInput, $service: String){
        updateTask(task: $task, service: $service)
    }
`;

const completeTaskMutation = gql`
    mutation completeTask($task: com_methodia_cockpit_core_domain_TaskInput, $service: String){
        completeTask(task: $task, service: $service)
    }
`;

const deleteTaskMutation = gql`
    mutation deleteTask($taskId: String!){
        deleteTask(taskId: $taskId)
    }
`;

const createTaskCommentMutation = gql`
    mutation createTaskComment($taskId: String!, $comment: com_methodia_cockpit_core_domain_TaskCommentInput, $service: String){
        createTaskComment(taskId: $taskId, comment: $comment, service: $service)
    }
`;

const getTaskCommentsQuery = gql`
    query getTaskComments($taskId: String!, $service: String){
        getTaskComments(taskId: $taskId, service: $service){
            message
            id
            author
            time
        }
    }
`;


const startProcessMutation = gql`
    mutation startProcess($processId:String!, $variables:[com_methodia_cockpit_core_domain_VariableInput]){
        startProcess(processId:$processId, variables: $variables)
    }
`;

const activateMeterPoint = gql`
    mutation activateMeterPoint($meterPointId: String!, $endDate: OB_Date!) {
        OB_processMeterPoint(meterPointId: $meterPointId, endDate: $endDate, action: "ACTIVATION") {
            id
            name
        }
    }
`;

const periodsQuery = gql`
    query periodsQuery {
        periods {
            id
            periodNo
            periodType
            name
            organization {
                id
            }
            year {
                fiscalYear
            }
        }
    }
`;
const getAddresses = gql`
    query getAddresses($postcode: String!) {
        getAddresses(postcode: $postcode){
            block
            city
            county
            dependentCity
            dependentStreet
            doubleDependentCity
            houseNumber
            houseNumberAdd
            mpan
            postalCode
            street
            ldz
            countryCode
            meterData{
                mprn
                gas_meter_serial_number
            }
        }
    }
`;

const getLdz = gql`
    query getLdz($postcode: String!) {
        getLdz(postcode: $postcode)
    }
`;

const createUpdateQuote = gql`
    mutation createUpdateQuote($quote: com_methodia_salesportal_domain_QuoteInput){
        createUpdateQuote(quote: $quote)
    }
`;

const createOpenbravoSalesOrganization = gql`
    mutation createOpenbravoSalesOrganization($salesOrganization: com_methodia_salesportal_domain_OpenbravoSalesOrganizationInput){
        createOpenbravoSalesOrganization(salesOrganization: $salesOrganization)
    }
`;

const salesOrganizationsQuery = gql`
    query salesOrganizations($pageNumber: Int, $pageSize: Int, $sort: com_methodia_salesportal_domain_SortInput, $where: String) {
        getSalesOrganizations(pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort, where: $where){
            content{
                organizationId
                businessPartnerId
                name
                active
                category
                avatarUrl
                status{
                    key
                    name
                    requireReason
                }
                parent {
                    name
                }
            }
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            random
            pageable{
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }
`;

const getQuote = gql`
    query getQuoteByTaskId($processInstanceId: String!){
        getQuoteByProcessInstanceId(processInstanceId: $processInstanceId){
            id
            extraData
            companyNumber
            products{
                name
                unitRate
                standingCharge
                commission
                commissionUnitRate
                commissionStandingCharge
                meterPoint
                annualQuantity
                annualQuantityIndustry
                greenGas
                amr
                greenEnergy
            }
            date{
                time
            }
            status{
                key
                name
            }
            statusReason
            clientName
            tariffName{
                aqHigh
                aqLow
                billingFrequency
                brokerId
                earliestStartDate
                id
                latestStartDate
                ldz
                name
                sc
                tariffCode
                unitRate
            }

            supplyAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
                exz
            }
            smartMeter
            ownerOrganizationId
            subOwnerOrganizationId
            ownerUserId
            processInstanceId
            startDate
            endDate
            saleType
            contractDuration
        }
    }
`

const getQuoteById = gql`
    query getQuote($id: Int){
        getQuote(id: $id){
            id
            extraData
            companyNumber
            products{
                name
                unitRate
                standingCharge
                commission
                commissionUnitRate
                commissionStandingCharge
                meterPoint
                annualQuantity
                annualQuantityIndustry
                greenGas
                amr
                greenEnergy
            }
            clientName
            tariffName{
                aqHigh
                aqLow
                billingFrequency
                brokerId
                earliestStartDate
                id
                latestStartDate
                ldz
                name
                sc
                tariffCode
                unitRate
            }

            supplyAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                exz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
                meterData{
                    mprn
                    gas_meter_serial_number
                }
            }
            smartMeter
            ownerOrganizationId
            subOwnerOrganizationId
            ownerUserId
            startDate
            endDate
            saleType
        }
    }
`

const createUpdateAgreement = gql`
    mutation createUpdateAgreement($agreement: com_methodia_salesportal_domain_AgreementInput){
        createUpdateAgreement(agreement: $agreement)
    }
`;

const setQuoteStatusMutation = gql`
    mutation setQuoteStatusMutation($quoteId: Int, $quoteStatus: com_methodia_salesportal_domain_QuoteStatusInput, $statusReason: String){
        setQuoteStatus(quoteId: $quoteId, quoteStatus: $quoteStatus, statusReason: $statusReason)
    }
`;

const getQuotesQuery = gql`
    query getQuotesQuery($organizationId: String, $username: String, $pageNumber: Int, $pageSize: Int, $sort: com_methodia_salesportal_domain_SortInput, $where: String){
        getQuotes(organizationId: $organizationId, username: $username, pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort, where: $where){
            content{
                id
                clientName
                contractDuration
                supplyAddress{
                    postalCode
                }
                date{
                    time
                }
                tariff
                tariffName{
                    aqHigh
                    aqLow
                    billingFrequency
                    brokerId
                    earliestStartDate
                    id
                    latestStartDate
                    ldz
                    name
                    sc
                    tariffCode
                    unitRate
                }

                products{
                    annualQuantity
                    name
                    meterPoint
                    commissionUnitRate
                    commissionStandingCharge
                }
                status{
                    key
                    name
                }
                statusReason
                processInstanceId
                ownerUserId
                ownerOrganizationName
                subOwnerOrganizationName
                saleType
                startDate
                endDate
            }
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            random
            pageable{
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }
`;

const getCommissionReportsQuery = gql`
    query getCommissionReportsQuery($organizationId: String!){
        getCommissionReports(organizationId: $organizationId){
            id
            startDate{
                time
            }
            endDate{
                time
            }
            created{
                time
            }
            createdBy
            document
            ownerOrganizationId
        }
    }
`;
const createCommissionReportMutation = gql`
    mutation createCommissionReportMutation($startDate: Date!, $endDate:Date!, $brokerId:String!){
        createCommissionReport(startDate: $startDate, endDate:$endDate, brokerId:$brokerId)
    }
`;
const createSalesUserMutation = gql`
    mutation CreateSalesUserMutation($user: com_methodia_salesportal_domain_CockpitUserInput){
        createSalesUser(user: $user)
    }
`;

const updateSalesUserMutation = gql`
    mutation UpdateSalesUserMutation($user: com_methodia_salesportal_domain_CockpitUserInput){
        updateSalesUser(user: $user)
    }
`;

const disableOpenbravoSalesOrganization = gql`
    mutation DisableOpenbravoSalesOrganization($broker: com_methodia_salesportal_domain_OrganizationInput!){
        disableOpenbravoSalesOrganization(broker: $broker){
            isActive
        }
    }
`;
const getQuoteStatusFlowFromQuery = gql`
    query GetQuoteStatusFlowFrom($from: com_methodia_salesportal_domain_QuoteStatusInput!){
        getQuoteStatusFlowFrom(from: $from){
            to {
                key
                name
                requireReason
            }

        }
    }
`;
const getAgreementStatusFlowFromQuery = gql`
    query GetAgreementStatusFlowFrom($agreement: Int!){
        getAgreementStatusFlowFrom(agreement: $agreement){
            to {
                key
                name
                action
                requireReason
            }

        }
    }
`;
const updateAgreementStartDateQuery = gql`
    mutation changeStartDate($agreementId: Int!, $date: Date!) {
        updateAgreementStartDate(agreementId: $agreementId, date: $date)
    }
`;
const agreementsQuery = gql`
    query getAgreementsQuery($organizationId: String, $username: String, $pageNumber: Int, $pageSize: Int, $sort: com_methodia_salesportal_domain_SortInput, $where: String) {
        getAgreements(organizationId: $organizationId, username: $username, pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort, where: $where){
            content{
                id
                obId
                processInstanceId
                date {
                    time
                }
                documentNumber
                clientName
                salesOrgName
                tariff
                tariffName{
                    name
                }
                products{
                    name
                    meterPoint
                }
                extraData
                status{
                    key
                    name
                    action
                    requireReason
                }
                startDate
                endDate
                signDate
                ownerOrganizationId
                subOwnerOrganizationId
                ownerOrganizationName
                subOwnerOrganizationName
            }
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            random
            pageable{
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }
`;

const agreementQuery = gql`
    query getAgreementQuery($id: Int) {
        getAgreement(id: $id){
            aq
            billingAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            billingContact{
                accountPassword
                email
                firstName
                lastName
                phone
                sameAsPrimary
                billingPreferencePost
                billingPreferenceEmail
            }
            businessType
            companySize
            clientName
            commission
            companyNumber
            contractDuration
            date{
                time
            }
            documentNumber
            endDate
            extraData
            id
            ldz
            mpan
            mprn
            obId
            ownerOrganizationId
            subOwnerOrganizationId
            ownerOrganizationName
            ownerUserId
            primaryAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            primaryContact {
                accountPassword
                email
                firstName
                lastName
                phone
            }
            previousAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            processInstanceId
            products {
                amr
                annualQuantity
                annualQuantityIndustry
                commission
                commissionStandingCharge
                commissionUnitRate
                greenEnergy
                greenGas
                meterPoint
                name
                standingCharge
                unitRate
            }
            salesOrgName
            secondaryAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            creditCheckAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            secondaryContact{
                accountPassword
                email
                firstName
                lastName
                phone
            }
            creditCheckContact{
                accountPassword
                email
                firstName
                lastName
                phone
                dateOfBirth
            }
            sourceQuoteId
            startDate
            signDate
            status{
                key
                name
            }
            supplyAddress {
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                exz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            sortCode
            bankAccountName
            accountNumber
            paymentMethod
            tariffName {
                aqHigh
                aqLow
                bespokePrice
                billingFrequency
                brokerId
                earliestStartDate
                gas_meter_serial_number
                id
                latestStartDate
                ldz
                name
                sc
                tariffCode
                unitRate
            }
            saleType
            SMS
            email
            mail
            telephone
            loaStartDate
            loaEndDate
            changeOfTenancy
            changeOfTenancyDate
            climateChangeAgreement
            ccaDate
            vatCertificate
            percentDomestic
            vatCertificateValidFromDate
            vatCertificateValidToDate

        }
    }
`;
const setAgreementStatusMutation = gql`
    mutation setAgreementStatusMutation($agreementId: Int, $agreementStatus: com_methodia_salesportal_domain_AgreementStatusInput){
        setAgreementStatus(agreementId: $agreementId, agreementStatus: $agreementStatus)
    }
`;

const getQuoteStatusesQuery = gql`
    query getQuoteStatuses{
        getQuoteStatuses{
            key
            name
        }
    }
`;

const getAgreementStatusesQuery = gql`
    query getAgreementStatuses{
        getAgreementStatuses{
            key
            name
        }
    }
`;
const getQuoteProductNamesQuery = gql`
    query getQuoteProductNames{
        getQuoteProductNames
    }
`;

const getOrganizationParentsQuery = gql`
    query getOrganizationParents{
        getOrganizationParents{
            name
            organizationId
        }
    }
`;

const saveUiTransportMutation = gql`
    mutation saveUiTransportMutation($uiTransportWrapper:com_methodia_cockpit_core_domain_ui_UiTransportWrapperInput){
        saveUiTransport(uiTransportWrapper: $uiTransportWrapper)
    }
`;

const contractsListPagedQuery = gql`
    query contractsListPaged($pageNumber: Int, $pageSize: Int, $sort: com_methodia_cockpit_core_domain_SortInput, $where: String){
        contractsListPaged(pageNumber:$pageNumber, pageSize: $pageSize, sort:$sort, where: $where){
            content{
                id
                documentnumber
                businessPartner{
                    id
                }
                estate{
                    mDMEstate {
                        id
                        estateNumber
                        locationAddress {
                            mdmZip
                        }
                        mDMMeterPointList{
                            name
                            mDMMeterRegisterList{
                                meter{
                                    serialNumber
                                }
                            }
                        }
                    }
                }
            }
            count
            first
            last
            number
            numberOfElements
            totalElements
            totalPages
            pageable{
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }
`;

const meterPointsPagedQuery = gql`
    query meterPointsPaged($pageNumber: Int, $pageSize: Int, $where: String){
        OB_meterPointPage(page:{pageSize:$pageSize, pageNumber:$pageNumber,sort:{orders:{direction:"desc", property:"name"}}}, specification:{where:$where}){
            content{
                id
                name
            }
            count
            first
            last
            number
            numberOfElements
            totalElements
            totalPages
            pageable{
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }
`;

const getBpmServiceInstancesQuery = gql`
    query getBpmServiceInstances{
        getBpmServiceInstances
    }
`;

const signContract = gql`
    mutation OB_signContract($contractId: String) {
        OB_signContract(contractId: $contractId) {
            id
        }
    }
`;

const getVendorsQuery = gql`
    query vendor ($where: String){
        OB_businessPartner(where: $where) {
            id
            name
            businessPartnerCategory {
                name
            }
        }
    }
`;

const insertSfSessionMutation = gql`
    mutation insertSfSessionMutation($UUID: String){
        insert_sf_session_user_session(objects: {session_id: $UUID}) {
            returning {
                session_id
            }
        }
    }
`;

const insertFormDataSfSessionMutation = gql`
    mutation  insertFormDataSfSessionMutation($content: json, $session_id: String, $step: String){
        insert_sf_session_form_data(objects: {content: $content, session_id: $session_id, step: $step}) {
            affected_rows
        }
    }
`;

const updateSfSessionMutation = gql`
    mutation updateSfSessionMutation($UUID: String, $submitted: Boolean, $response: String, $timestamp: timestamp){
        update_sf_session_user_session(_set: {submitted: $submitted, submitted_at: $timestamp, response_code: $response}, where: {session_id: {_eq: $UUID}}) {
            returning {
                session_id
            }
        }
    }`;

const scheduleExecution = gql`
    query ScheduleExecution($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
        ScheduleExecution(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, where: $where) {
            content {
                id
                created
                reportName
                status
                parameters
                schedule
                periodCount
                periodType
                skipNextExecutionDate
            }
            pageable {
                pageNumber
                pageSize
                paged
            }
            size
            totalPages
            number
            numberOfElements
            totalElements
        }
    }
`;

const sitesByProperty = gql`
    query SitesByProperty($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
        SitesByProperty(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, where: $where) {
            content {
                id
                name
                address
                startDate
                endDate
                site {
                    id
                    name
                    address
                    meterPoints {
                        id
                        identifier
                        purpose
                        supplyDate
                        hasAmr
                        amrScheduleCode
                        hasCorrector
                        name
                        site {
                            name
                        }
                        registers {
                            id
                            digits
                            rate
                            readings {
                                date
                                type
                                value
                                uploadMethod
                            }
                            consumptions {
                                presentReading {
                                    date
                                    type
                                    value
                                }
                            }
                            meter {
                                id
                                serialNumber
                                type
                            }
                        }
                    }
                }
                properties {
                    startDate
                    endDate
                    site {
                        meterPoints {
                            id
                            identifier
                            registers {
                                id
                                digits
                                rate
                                readings {
                                    date
                                    type
                                    value
                                    uploadMethod
                                }
                                consumptions {
                                    presentReading {
                                        date
                                        type
                                        value
                                    }
                                }
                                meter {
                                    id
                                    serialNumber
                                    type
                                }
                            }
                        }
                    }
                }
                status
                account {
                    number
                    status
                }
            }
            size
            totalPages
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            pageable {
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }

`;

const customers = gql`query Customer($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
    Customer(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, spec: {where: $where}) {
        content {
            id
            name
            number
            referenceNumber
            address
            status
            created
            createdBy
            updated
            updatedBy
            accounts {
                id
                number
                properties {
                    id
                    site {
                        id
                        address
                        name
                        meterPoints {
                          id
                          identifier
                          purpose
                          supplyDate
                          hasAmr
                          amrScheduleCode
                          hasCorrector
                          name
                          status
                          site {
                            name
                          }
                          registers {
                            id
                            digits
                            rate
                            rateId
                            readings {
                              date
                              type
                              value
                            }
                            consumptions {
                              presentReading {
                                date
                                type
                                value
                              }
                            }
                            meter {
                              id
                              serialNumber
                              type
                            }
                          }
                        }
                    }
                }
            }
            accounts {
                number
            }
        }
        size
        totalPages
        first
        last
        number
        numberOfElements
        size
        totalElements
        totalPages
        pageable {
            pageNumber
            pageSize
            paged
            offset
        }
    }
}
`;

const customersList = gql`query CustomersList (
    $pageNumber: Int,
    $pageSize: Int,
    $where: String,
    $sort: SortInput,
    $id: Boolean = true,
    $number: Boolean = true,
    $name: Boolean = true,
    $referenceNumber: Boolean = true,
    $status: Boolean = true,
    $address: Boolean = true,
    $contracts: Boolean = true,
    $accounts: Boolean = true
) {
    Customer(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, spec: {where: $where}) {
        content {
            id @include(if: $id)
            number @include(if: $number)
            name @include(if: $name)
            referenceNumber @include(if: $referenceNumber)
            status @include(if: $status)
            address @include(if: $address)
            contracts @include(if: $contracts) {
                product
                status
            }
            accounts @include(if: $accounts) {
                balance {
                    amount
                }
                number
                properties {
                    name
                    site {
                        meterPoints {
                            id
                            identifier
                            purpose
                            supplyDate
                            hasAmr
                            amrScheduleCode
                            hasCorrector
                            name
                            status
                            site {
                                name
                            }
                            registers {
                                id
                                digits
                                rate
                                rateId
                                readings {
                                    date
                                    type
                                    value
                                }
                                consumptions {
                                    presentReading {
                                        date
                                        type
                                        value
                                    }
                                }
                                meter {
                                    id
                                    serialNumber
                                    type
                                }
                            }
                        }
                    }
                }
            }
        }
        size
        totalPages
        first
        last
        number
        numberOfElements
        size
        totalElements
        totalPages
        pageable {
            pageNumber
            pageSize
            paged
            offset
        }
    }
}`;

const meterPointNames = gql`query MeterPointNames($where:String, $field:String) {
    MeterPoint (page: {pageSize: 100, pageNumber: 0, sort:{orders: [{property: $field, direction: "asc"}]}}, spec:{where: $where}) {
        content {
            identifier
            name
        }
    }
}`;

const customerNames = gql`query CustomersList($where:String, $field:String) {
    Customer (page: {pageSize: 100, pageNumber: 0, sort:{orders: [{property: $field, direction: "asc"}]}}, spec:{where: $where}) {
        content {
            id
            name
        }
    }
}`;

const siteNames = gql`query SitesList($where:String, $field:String) {
    Site (page: {pageSize: 100, pageNumber: 0, sort:{orders: [{property: $field, direction: "asc"}]}}, spec:{where: $where}) {
        content {
            id
            name
        }
    }
}`;


const accountNumbers = gql`query AccountNumbers($where:String, $field:String) {
    Account (page: {pageSize: 100, pageNumber: 0, sort:{orders: [{property: $field, direction: "asc"}]}}, spec:{where: $where}) {
        content {
            id
            number
        }
    }
}`;

const flogasAccount = gql`
    query flogasAccount {
        flogasv_account {
            id
            number
            type
            acct_address
            bill_address
            isactive
            customer {
                name
                number
            }
            meterpoint {
                id
                type
                number
            }
        }
    }
`;

export const insertFlogasReadingOne = gql`
    mutation insertFlogasReadingOne($meterpoint_id: uuid, $user_comment: String, $read_date: timestamp, $read1: numeric, $read2: numeric) {
        insert_flogas_reading_one(object: {meterpoint_id: $meterpoint_id, user_comment: $user_comment, read_date: $read_date, read1: $read1, read2: $read2}) {
            id
        }
    }
`;
const serviceRequests = gql`
    query MetroRequest($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
        MetroRequest(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, spec: {where: $where}) {
            content {
                address
                comment
                currentStatus
                customerEmail
                customerIdentifier
                customerName
                customerPhone
                metroCard
                requestDate
                responsiblePerson
                requestNumber
                serviceRequestId
                statusChangeDate
                serviceName
                acceptanceDate
                duplicatedRequest
            }
            pageable {
                pageNumber
                pageSize
                paged
            }
            size
            totalPages
            number
            numberOfElements
            totalElements
        }
    }
`;

const getOrganizationStatusesQuery = gql`
    query GetOrganizationStatusesQuery {
        getOrganizationStatuses{
            action
            key
            name
            requireReason
        }
    }
`;

const setOrganizationStatusMutaton = gql`
    mutation setOrganizationStatusMutation($orgId: Int!, $statusKey:String) {
        setOrganizationStatus(statusKey:$statusKey, orgId:$orgId)
    }
`;


const updateServiceRequestStatus = gql`
    mutation updateServiceRequestStatus($serviceRequestId: UUID!, $newStatus: StatusChangeInput!, $comment: String) {
        updateServiceRequestStatus(serviceRequestId: $serviceRequestId, newStatus: $newStatus, comment: $comment) {
            id
        }
    }
`;
const insertFlogasEnquiry = gql`
    mutation insertFlogasEnquiry($account_id: String, $enq_type: String, $enq_description: String, $supply_address: String, $user_email: String, $user_name: String) {
        insert_flogas_enquiry(objects: {account_id: $account_id, enq_type: $enq_type, enq_description: $enq_description, supply_address: $supply_address, user_email: $user_email, user_name: $user_name}) {
            returning {
                id
            }
        }
    }
`;

const flogasDashboardCards = gql`
    query flogasDashboardCards {
        flogas_reading(limit: 1, order_by: {created: desc}) {
            created
        }

        flogas_invoice(limit: 1, order_by: {issue_date: desc}) {
            issue_date
        }
    }
`;

const portalEntityStatus = gql`
    query portalEntityStatus {
        portal_entity_status {
            comment
            created
            createdby
            entity_name
            id
            status
        }
    }
`;

const insertPortalEntityStatus = gql`
    mutation insertPortalEntityStatus($record_id: String, $comment: String, $created: timestamp, $createdby: String, $entity_name: String, $status: String ) {
        insert_portal_entity_status(objects: {record_id: $record_id, comment: $comment, created: $created, createdby: $createdby, entity_name: $entity_name, status: $status}) {
           affected_rows
        }
    }
`;

const MyQueries = gql`
query MyQueries($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
  Query(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, where: $where) {
    content {
      id
      name
      description
      assignee
      owner
      priority
      createTime
      variables
      attachmentCount
    }
    size
    totalPages
    first
    last
    number
    numberOfElements
    size
    totalElements
    totalPages
    pageable {
      pageNumber
      pageSize
      paged
      offset
    }
  }
}
`

//Next queries are for Hartree Supply Comparison
const meterPoints = gql`
    query MeterPoints{
        meter_points_ex{
        meter_point_identifier,
        site_name,
        ui_search_key,
        counterparty_name
        counterparty_id
    }
}`;

const settlementRunGQL = gql`
    query SettlementRun($meter_point_identifier: String, $valuedate: data){
        meter_point_settlement_run_ex(where:{meter_point_identifier: {_eq: $meter_point_identifier}, valuedate: {_eq: $valuedate}}){
            settlement_run,
            counterparty_id
        }
    }`;

const versionGQL = gql`
    query Version($meter_point_identifier: String,  $counterparty_id: Int, $valuedate: date){
    meter_point_versions_ex(where:{meter_point_identifier: {_eq: $meter_point_identifier}, valuedate:{_eq: $valuedate}, counterparty_id: {_eq: $counterparty_id}}){
        version,
        counterparty_id
        }
    }
`;

const curveComparison = gql` 
query CurveComparison ($counterparty_id: Int, $meter_point_identifier: String, $valuedate: date, $sr: String, $version: String, $other_sr: String, $other_version: String){
    curve_comparison_ex(args: {
            counterparty_id: $counterparty_id,
            meter_point_identifier: $meter_point_identifier,
            valuedate: $valuedate,
            sr: $sr, 
            version: $version,
            other_sr: $other_sr, 
            other_version: $other_version})
        {
            curve_name
            value
            other_value
            diff
        }
    }
`

const shortCurveComparison = gql`
query MeterPointCurves($counterparty_id: Int, $meter_point_identifier: String, $valuedate: date, $sr: String, $version: date) {
  meter_point_curves(args: {counterparty_id: $counterparty_id, meter_point_identifier: $meter_point_identifier, valuedate: $valuedate, sr: $sr, version: $version}) {
    curve_name
    value
  }
}
`

const DNOdashboard = gql`
    query DNOdashboard {
        dno_dashboard_latest {
            run_date
            dno
            dno_id
            dno_period
            dno_month_total
        }
    }
`

const ElexonDashboard = gql`
query ElexonDashboard {
    elexon_dashboard_latest2 {
      id
      run_date
      payment_date
      nonsf_adjustment
      cashout_adjustment
      rcrc_adjustment
      invoice
      status
      createdby
    }
}
`

const weeklyValidationQuery = gql`
    query weeklyValidationQuery {
        gas_readings {
            corrected_cns_consumption
            corrected_rd1_consumption
            corrected_rd1_reading
            uncorrected_cns_consumption
            uncorrected_rd1_consumption
            uncorrected_rd1_reading
            meter_serial_number
            convertor_serial_number
            status
            site
            mprn
            meter_reading
            id
            efficiency
            is_efficiency_in_range
            total_power_exported
            date
        }
    }
`

const DNOdashboard2 = gql`
query DNOdashboard {
        dno_dashboard_latest2 {
            id
            run_date
            reportdesc
            reportlink
            status
            createdby
        }
    }
`

const businesspartner = gql`
query businessPartnerQuery($where:String, $orderBy:String) {
    businessPartner(startRow: 0, offset: 100, where: $where, orderBy: $orderBy) {
        id
        name
        mdmContragentno
    }
}`

const insertReportActionMutation = gql`
    mutation InsertReportAction($report_id: String, $action: String, $createdBy: String){
        insert_report_action(objects: {report_id: $report_id, action: $action, createdby: $createdBy }) {
            affected_rows
        }
    }
`;

const OB_createGasReading = gql`
    mutation OB_createGasReading ($meterSerialNumber: String!, $meterPointNumber: String!, $correctorSerialNumber: String, $readingValue: OB_Long!, $readingCorrectedValue: OB_Long!, $readingUncorrectedValue: OB_Long!, $readingDate: OB_Date!, $process: String, $channel: String!){
        OB_createGasReading( meterSerialNumber: $meterSerialNumber, meterPointNumber: $meterPointNumber, correctorSerialNumber: $correctorSerialNumber, readingValue: $readingValue, readingCorrectedValue: $readingCorrectedValue, readingUncorrectedValue: $readingUncorrectedValue, readingDate: $readingDate, process: $process, channel: $channel) {
            id
        }
    }
`

const updateGasReading = gql`
    mutation UpdateGasReading($mprn: String, $date: date, $status: String) {
        update_gas_readings(where: {mprn: {_eq: $mprn}, date: {_eq: $date}}, _set: {status: $status}) {
            affected_rows
        }
    }
`

const findAllNominationQuantitiesQuery = gql`
    query FindAllNominationQuantities($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
        findAllNominationQuantities(
            spec: { where: $where },
            page: { pageSize: $pageSize, pageNumber: $pageNumber, sort: $sort }
        ) {
            content {
                effectiveFrom
                meterNumber
                meterPointIdentifier
                quantity
            }
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            pageable {
                offset
                pageNumber
                pageSize
                paged
            }
        }
    }
`;

const findNominationLastEffectiveDate = gql`
    query FindAllNominationQuantities($where: String) {
        findAllNominationQuantities(
            spec: { where: $where }
            page: {
                pageSize: 1
                pageNumber: 0
                sort: { orders: [{ property: "effectiveFrom", direction: "desc" }] }
            }
        ) {
            content {
                effectiveFrom
            }
        }
    }
`;

const getBusinessPartnermDMMeterPointListQuery = gql`
    query businessPartner($where: String!) {
        businessPartner(startRow: 0, offset: 100, where: $where) {
            mDMBPEstateList {
                mDMEstate {
                    mDMMeterPointList{
                        name
                    }
                }
            }
        }
    }
`;

const getBillingAccountsByBpId = gql`
    query businessPartnerQuery($where: String!) {
        businessPartner(startRow: 0, offset: 1, where: $where, orderBy: "name") {
            bILLBillingAccountList {
                id
                isActive
                billingAccountNumber
                paymentMethod {
                    name
                }
                currentStatus {
                    status
                }
            }
        }
    }
`;

const createNomination = gql`
    mutation CreateNomination($executionContextIdentifier: String, $executionContextType : String, $serviceProduct: String, $startDate: LocalDateTime, $endDate: LocalDateTime, $volumes: [VolumeInputInput]) {
        createNomination(
            nomination: {
                endDate: $endDate
                executionContextType: $executionContextType
                serviceProduct: $serviceProduct
                executionContextIdentifier: $executionContextIdentifier
                startDate: $startDate
                volumes:  $volumes
            }
        )
    }
`;

const findAllNominations = gql`
    query FindAllNominations($where: String!, $pageSize: Int, $sort: SortInput, $pageNumber: Int) {
        findAllNominations(
            spec: { where: $where }
            page: { pageSize: $pageSize, sort: $sort, pageNumber: $pageNumber }
        ) {
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            content {
                endDateUTC
                executionContextIdentifier
                executionContextType
                isPublished
                quantity
                service
                settlementDay
                startDateUTC
                status
                versionDate
            }
            pageable {
                offset
                pageNumber
                pageSize
                paged
                unpaged
            }
        }
    }
`;

const findLastNominationsByIdAndDate = gql `
    query FindLastNominationsByIdAndDate($date: LocalDate!, $executionIdentifier: String!) {
        findLastNominationsByIdAndDate(date: $date, executionIdentifier: $executionIdentifier) {
            endDateUTC
            executionContextIdentifier
            executionContextType
            isPublished
            quantity
            service
            settlementDay
            startDateUTC
            status
            versionDate
        }
    }
`;

export {
    getLastInvoiceDate,
    getLastReadingDate,
    updateGasReading,
    OB_createGasReading,
    MyQueries,
    businesspartner,
    DNOdashboard,
    DNOdashboard2,
    weeklyValidationQuery,
    meterPoints,
    settlementRunGQL,
    versionGQL,
    curveComparison,
    shortCurveComparison,
    getBusinessPartnerShortQuery,
    getBusinessPartnerMiddleQuery,
    getBusinessPartnerQuery,
    getInvoiceQuery,
    getBalance,
    getContractQuery,
    getUserQuery,
    getUsers,
    getAllRoles,
    getAllPermissions,
    getRolesForUserQuery,
    createUserMutation,
    updateUserMutation,
    deleteUserMutation,
    createRolePermissionsMutation,
    deleteRolePermissionsMutation,
    createUpdateRolesMutation,
    changePasswordMutation,
    changeOwnPasswordMutation,
    createUserRoles,
    deleteUserRoles,
    createNoteMutation,
    updateNoteMutation,
    completeTaskMutation,
    deleteNoteMutation,
    uiConfigurationQuery,
    allUiConfigurationsQuery,
    allUiMenuItemsQuery,
    organizationsQuery,
    systemsQuery,
    createReadingMutation,
    getTasksQuery,
    getTaskVariableQuery,
    createTaskMutation,
    updateTaskMutation,
    deleteTaskMutation,
    createTaskCommentMutation,
    getTaskCommentsQuery,
    startProcessMutation,
    periodsQuery,
    getContractId,
    getAddresses,
    createUpdateQuote,
    createUpdateAgreement,
    createOpenbravoSalesOrganization,
    getQuote,
    setQuoteStatusMutation,
    getQuotesQuery,
    createSalesUserMutation,
    updateSalesUserMutation,
    disableOpenbravoSalesOrganization,
    getCommissionReportsQuery,
    createCommissionReportMutation,
    getQuoteStatusFlowFromQuery,
    getAgreementStatusFlowFromQuery,
    getQuoteById,
    agreementsQuery,
    agreementQuery,
    setAgreementStatusMutation,
    getLdz,
    salesOrganizationsQuery,
    getQuoteStatusesQuery,
    getAgreementStatusesQuery,
    getQuoteProductNamesQuery,
    updateSystemMutation,
    unlockUserMutation,
    getOrganizationParentsQuery,
    saveUiTransportMutation,
    contractsListPagedQuery,
    getBpmServiceInstancesQuery,
    signContract,
    getVendorsQuery,
    insertSfSessionMutation,
    insertFormDataSfSessionMutation,
    updateSfSessionMutation,
    getOrganizationStatusesQuery,
    scheduleExecution,
    sitesByProperty,
    customers,
    customersList,
    meterPointNames,
    customerNames,
    siteNames,
    insertFlogasEnquiry,
    flogasDashboardCards,
    portalEntityStatus,
    insertPortalEntityStatus,
    flogasAccount,
    setOrganizationStatusMutaton,
    meterPointsPagedQuery,
    activateMeterPoint,
    serviceRequests,
    updateServiceRequestStatus,
    updateAgreementStartDateQuery,
    brokerBalance,
    mpanNames,
    Properties,
    getNumberOfUnreadMessages,
    SingleNotification,
    ElexonDashboard,
    insertReportActionMutation,
    findAllNominationQuantitiesQuery,
    findNominationLastEffectiveDate,
    getBusinessPartnermDMMeterPointListQuery,
    getBillingAccountsByBpId,
    createNomination,
    findAllNominations,
    findLastNominationsByIdAndDate
};