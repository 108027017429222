import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {t} from "i18next";

const PasswordPolicy = () => {
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    const fetchPolicy = async () => {
      const response = await axios.get(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/password-policy`);
      let array = Object.entries(response.data).filter(pair => pair[1] > 0);
      setPolicy(Object.fromEntries(array));
    };

    fetchPolicy();
  }, []);

  let passwordPolicy = t("profile.passwordpolicy") + ": ";
  if (policy) {
    // Create an array of policy keys to iterate over them and build the policy string (translation) with the values from the policy (state) object.
    const policyKeys = ['minLength', 'minLowercaseLetters', 'minUppercaseLetters', 'minDigits', 'minSpecialChars'];

    policyKeys.forEach((key, i) => {
      if (policy[key]) {
        const policyItem = t(`profile.${key}`, {[key]: policy[key]});
        if (i === 0) passwordPolicy += policyItem;
        else passwordPolicy += ', ' + policyItem;
      }
    });
  }

  return <span style={{fontSize: 'small', color:'gray'}}>{passwordPolicy}</span>;
};

export default PasswordPolicy;
