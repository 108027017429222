import React, { useEffect } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";

import axios from "axios";
import { Row, Col } from "reactstrap";

const SupersetDashboard = ({ id, supersetDomain, dashboardName }) => {
	useEffect(() => {
		axios
			.get("/bi-guest-token", {
				headers: {
					Authorization: `Bearer ${localStorage.token}`,
				},
			})
			.then(({ data }) => {
				embedDashboard({
					id: id,
					supersetDomain: supersetDomain,
					mountPoint: document.getElementById("superset-dashboard-container"),
					fetchGuestToken: () => data.token,
					dashboardUiConfig: {
						hideTitle: true,
						filters: {
							expanded: false,
						},
					},
				});
			})
			.catch((e) => console.log(e));
	}, []);

	return (
		<div>
			<Row className="subheader">
				<Col className="col-8">
					<h1>{dashboardName}</h1>
				</Col>
			</Row>
			<Row className="page-cnt">
				<div id="superset-dashboard-container"></div>
			</Row>
		</div>
	);
};

export default SupersetDashboard;
