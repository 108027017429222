import React, { useEffect, useState, Fragment } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import { SingleDatePicker } from "react-dates";
import { Col, Input, Label, Row } from "reactstrap";

import {
	findAllNominations,
	getBillingAccountsByBpId,
} from "../../queries/Queries";
import PaginatedQueryDataGrid from "../../components/PaginatedQueryDataGrid/PaginatedQueryDataGrid";

const NominationsGrid = ({ client, match }) => {
	const [selectedBillingAccount, setSelectedBillingAccount] = useState(null);
	const [billingAccounts, setBillingAccounts] = useState([]);
	const [selectedDate, setSelectedDate] = useState(moment());
	const [focus, setFocus] = useState(null);
	const refetchQueries = useSelector((state) => state.refetchQueries);
	const dispatch = useDispatch();

	useEffect(() => {
		const bpId = match.params.param; // get bp id from path;
		if (bpId) {
			async function getBillingAccounts() {
				const billingAccounts = await client.query({
					query: getBillingAccountsByBpId,
					variables: {
						where: `id = '${bpId}'`,
					},
				});

				const { bILLBillingAccountList } =
					billingAccounts.data.businessPartner[0];
				if (bILLBillingAccountList.length > 0) {
					setBillingAccounts(bILLBillingAccountList);
				}
			}

			getBillingAccounts();
		}
	}, []);

	return (
		<div id="nominations-grid">
			<div className="table-filers-cnt">
				<div className="table-filers-elements">
					<Row className="mb-3 align-items-start">
						<Col xs="4" sm="4">
							<Label>Billing Account:</Label>
							<Input
								type="select"
								value={selectedBillingAccount}
								onChange={(e) => {
									setSelectedBillingAccount(e.target.value);
								}}
							>
								<option hidden value="default">Select billing account</option>
								{billingAccounts.map((acc) => (
									<option key={acc.billingAccountNumber} value={acc.billingAccountNumber}>
										{acc.billingAccountNumber}
									</option>
								))}
							</Input>
						</Col>
						<Col xs="2" sm="2">
							<Label>Date:</Label>
							<SingleDatePicker
								small
								date={selectedDate}
								onDateChange={(date) => setSelectedDate(date)}
								displayFormat={"DD/MM/YYYY"}
								focused={focus}
								onFocusChange={({ focused }) => setFocus(focused)}
								showDefaultInputIcon={true}
								noBorder={false}
								numberOfMonths={1}
								isOutsideRange={() => false}
							/>
						</Col>
					</Row>
				</div>
			</div>
			<PaginatedQueryDataGrid
				paginationPath={"findAllNominations"}
				dataPath={"findAllNominations.content"}
				query={findAllNominations}
				variables={{
					where: `executionContextIdentifier == ${selectedBillingAccount} and settlementDay == ${selectedDate.format(
						moment.HTML5_FMT.DATE
					)}`,
				}}
				recordsPerPage={50}
				keyField={"startDateUTC"}
				defaultSorted={[
					{ dataField: "versionDate", order: "desc" },
					{ dataField: "startDateUTC", order: "asc" },
				]}
				columns={[
					{
						dataField: "settlementDay",
						text: "Settlement Day",
						sort: true,
						formatter: (cell) => moment(cell).format("DD/MM/YYYY"),
					},
					{
						dataField: "quantity",
						text: "Quantity",
						sort: true,
					},
					{
						dataField: "startDateUTC",
						text: "Start Time",
						sort: true,
						formatter: (cell) =>
							moment.utc(cell).tz("Europe/London").format("DD/MM/YYYY HH:mm"),
					},
					{
						dataField: "endDateUTC",
						text: "End Time",
						sort: true,
						formatter: (cell) =>
							moment.utc(cell).tz("Europe/London").format("DD/MM/YYYY HH:mm"),
					},
					{
						dataField: "versionDate",
						text: "Submitted Date",
						sort: true,
						formatter: (cell) =>
							moment.utc(cell).tz("Europe/London").format("DD/MM/YYYY HH:mm"),
					},
				]}
			></PaginatedQueryDataGrid>
		</div>
	);
};

export default withApollo(withRouter(NominationsGrid));
