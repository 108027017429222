import React, {Component} from "react";

import {Field, reduxForm, SubmissionError} from "redux-form";
import {Button, Card, CardBody, CardGroup, Col, Container, Row} from "reactstrap";
import {renderInput} from "./../../../RenderInput";
import i18n from "./i18n";
import {compose} from "react-apollo";
import {withRouter} from "react-router-dom";
import axios from "../../../utils/Client";
import { isValidEmail } from "../../../utils/Helpers";

const validate = values => {
    const errors = {}
    const requiredFields = ['username', 'password']
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = i18n.t('login.required')
        }
    })
    if (values.username && !isValidEmail(values.username)) {
        errors.username = i18n.t('login.invalidEmail')
    }
    return errors
}


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {error: {message: ""}}
    }

    handleFormSubmit(values, event, props) {
        return axios.post(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : ''))}/login`, {
                userName: values.username,
                password: values.password
            }, {
                headers: {'Content-Type': 'application/json'}
            })
            .then(response => {
                let data = response.data || response.response.data;

                if (!data.token) {
                    if (data.code) {
                        console.log('there was an error sending the query', data.code);
                    }
                    
                    if ((typeof data === 'string' && data.includes('expired')) || (data.message && data.message.includes('expired'))) {
                        this.props.history.push('/change-expired-password');
                    }
                    else if (data.message !== "" || data.includes('Bad credentials') || (data.message && data.message.includes('Bad credentials'))) {
                        this.setState({error: {message: "Invalid username and/or password."}});
                    } else {
                        this.setState({error: {message: data.message || data}});
                    }
                    
                    throw new SubmissionError({error: this.state.error.message || data.message || data});
                } else {
                    let expDate = new Date();
                    expDate.setTime(data.expirationDate);
                    document.cookie = "token=true;expires=" + expDate.toUTCString() + ";path=/";
                    localStorage.token = data.token;

                    const {pathname} = props.location;
                    const navigateTo = pathname.slice(6); //remove /login from the beginning
                    props.history.push(navigateTo);

                }

            }).catch(error => {
                console.log('there was an error sending the query', error);
                if (error.errors) {
                    if (error.errors.error && error.errors.error.includes('expired')) {
                        this.props.history.push('/change-expired-password');
                    }
                    this.setState({error: {message: error.errors.error}});
                } else {
                    if (error.message && error.message.includes('expired')) {
                        this.props.history.push('/change-expired-password');
                    }
                    this.setState({error: {message: error.message}});
                }
            });
    }

    required = value => (value ? undefined : 'Required')

    render() {
        //Additional step for mobile device login
        if (window.nsWebViewBridge) {
            window.nsWebViewBridge.emit('deleteToken');
        }

        const {handleSubmit, invalid, submitting} = this.props;

        const errorMessage = this.state.error ? <p className="text-danger login-err">{this.state.error.message}</p> :
            !(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) &&
            <p className="signin-text-muted">{i18n.t('login.subtitle')}</p>
        return (
            <div className="app flex-row align-items-center">
                <Container fluid={true}>
                    <Row className="justify-content-center">
                        <Col md="12" style={{paddingLeft: 0, paddingRight: 0}}>
                            <CardGroup>
                                <Card
                                    className={(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) ? "login-panel" : "p-4 login-panel"}>
                                    <CardBody className='credentials-card'>
                                        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                                            <div className='login-logo '></div>
                                            {errorMessage}
                                            <label htmlFor="username"
                                                className='label-name'>{i18n.t('login.username')}</label>
                                            <Field inputGroup={{className: "mb-3"}}
                                                placeholder={i18n.t('login.userEmail')}
                                                validate={[this.required]}
                                                name="username"
                                                onChange={() => this.setState({error: {message: ""}})}
                                                component={renderInput} type="email"/>
                                            <label htmlFor="password"
                                                className='label-name'>{i18n.t('login.password')}</label>
                                            <Field inputGroup={{className: "mb-4"}}
                                                placeholder={i18n.t('login.password')}
                                                onChange={() => this.setState({error: {message: ""}})}
                                                validate={[this.required]}
                                                name="password"
                                                component={renderInput} type="password"/>
                                            <Row className='margin-b-30'>
                                                <Col xs="12" className="text-right">
                                                    <Button color="link"
                                                        onClick={() => this.props.history.push('/reset-password')}
                                                        className="px-0">{i18n.t('login.forgottenPasswordLink')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <Button color="primary" className="px-4 sign-in" disabled={invalid || submitting}>
                                                        {submitting && <span><i className='fa fa-circle-o-notch fa-spin'></i> </span>}
                                                        {i18n.t('login.buttonLabel')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                                {false &&
                                    <Card>
                                        <CardBody className="text-center side-image">
                                        </CardBody>
                                    </Card>}
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const reduxFormWrapper = reduxForm({
    form: 'login',
    validate
});


export default compose(
    //gqlWrapper,
    reduxFormWrapper,
    withRouter
)(Login);
