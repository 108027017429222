import React, {Component} from "react";
import {Query} from 'react-apollo';
import gql from "graphql-tag";
import {withRouter} from "react-router-dom";
import _ from "underscore";
import InfoCard from "../InfoCard";
import i18n from '../../views/Pages/Login/i18n';
import {
    dateFormatter,
    rolesFormatter,
    prefCommChannelFormatter,
    priceFormatter,
    datetimeFormatter,
    momentFormatter
} from "../../utils/Formatters";
import Loading from "../Loading"

import jsonata from "./../../../node_modules/jsonata/jsonata-es5"
import {withApollo} from "react-apollo";

class QueryInfoCard extends React.Component {

    constructor(props) {
        super(props);
        this.setParents = this.setParents.bind(this);
        this.dateFormatter = dateFormatter.bind(this);
        this.datetimeFormatter = datetimeFormatter.bind(this);
        this.momentFormatter = momentFormatter.bind(this);
        this.rolesFormatter = rolesFormatter.bind(this);
        this.prefCommChannelFormatter = prefCommChannelFormatter.bind(this);
        this.priceFormatter = priceFormatter.bind(this);
        this.state = {
            isMutation: false,
            mutationResult: null,
            mutationComponent: null,
            mutationFetched: false,
            mutationInfo: []
        }
    }

    setParents(obj, parents) {
        let newObj = JSON.parse(JSON.stringify(obj));
        const ownProps = Object.keys(newObj);
        for (let ownProp of ownProps) {
            if (newObj[ownProp] instanceof Object || newObj[ownProp] instanceof Array) {
                let newparents = parents.slice();
                newparents.unshift(obj);
                newObj[ownProp] = this.setParents(newObj[ownProp], newparents);
            }
        }
        newObj.__parents = parents;
        return newObj;
    }

    getDescendantProp(obj, desc) {
        if (desc) {
            var arr = desc.replace(']', '').split(/\[|\./);
            while (arr.length && (obj = obj[arr.shift()])) ;
            return obj;
        }
    }

    getAllElements(obj, value) {
        if (value) {
            var arr = value.replace(']', '').split(/\[|\./);
            arr.forEach(el => obj = obj[el]);

            return obj.reduce((acc, curr) => {
                (!acc.includes(curr.product) && curr.status !== 'Terminated') && acc.push(curr.product);

                return acc
            }, []);
        }
    }

    render() {
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }
        if (this.props.queries[this.props.queryKey].toString().indexOf("mutation") !== -1 && !this.state.mutationFetched) {
            this.state.isMutation = true;

            this.props.client.mutate({
                mutation: gql`${this.props.queries[this.props.queryKey]}`,
                variables: variables
            }).then(result => {
                if (!result.loading) {
                    let newProps = [];
                    if (this.props.queryKey === "SingleNotification") {
                        this.props.info.map(obj => {
                            if (obj.key === "Subject") {
                                newProps.push({...obj, value: result.data.MarkNotificationByIdAsRead.type})
                            } else if (obj.key === 'Sent On') {
                                newProps.push({
                                    ...obj,
                                    value: dateFormatter(result.data.MarkNotificationByIdAsRead.sentOn)
                                })
                            } else if (obj.key === "Message") {
                                newProps.push({...obj, value: result.data.MarkNotificationByIdAsRead.message})
                            }
                        })
                    } else {
                        this.props.info.map(obj => {
                            newProps.push({...obj, value: result.data.queryKey[obj.key]})
                        })
                    }
                    this.setState({
                        mutationResult: result.data.MarkNotificationByIdAsRead,
                        mutationInfo: newProps,
                        mutationFetched: true
                    })
                }
            }).catch(error => alert(error.message));
        }
        return (
            <React.Fragment>
                {this.state.isMutation ? (
                    this.state.mutationResult ? (
                        <InfoCard {...this.props} info={this.state.mutationInfo} title={i18n.t(this.props.title)}
                                  data={this.state.mutationResult}/>) : (<Loading/>)
                ) : (
                    <Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>
                        {(result) => {
                            if (result.loading) return (<Loading/>);//<Loading/>;
                            if (result.error) return <div>{result.error} </div>;
                            const {data} = result;
                            let expr = null;
 
                            if (this.props.expression) {
                                const compiled = _.template(this.props.expression);
                                expr = compiled(this.props.match.params);
                            }

                            const expression = expr ? jsonata(expr) : null;
                            const transformedData = expression ? expression.evaluate(data) : data;
                            const dataWithParents = this.setParents(transformedData, []);

                            for (let x of this.props.info) {
                                if (x.expression) {
                                    x.value = x.needToLoop ? this.getAllElements(dataWithParents, x.value) : this.getDescendantProp(dataWithParents, x.value);
                                    
                                    // Check if the value is a JSON string and parse it to work with different jsonata expressions.
                                    if (typeof x.value === 'string') {
                                        try {
                                            x.value = JSON.parse(x.value);
                                        } catch (e) {
                                            console.error('Error parsing JSON:', e);
                                        }
                                    }
                                    x.expression = false;
                                    if (x.localExpression) {
                                        const localExpr = jsonata(x.localExpression);
                                        x.value = localExpr.evaluate(x.value);
                                    }
                                }

                                if (x.formatter) {
                                    if (typeof x.formatter === "function") {
                                        x.value = x.formatter(x.value, dataWithParents);
                                    } else {
                                        x.value = this[x.formatter](x.value, dataWithParents);
                                    }
                                }
                            }

                            const {children} = this.props;
                            const {queries, queryKey, variables, title, ...newProps} = this.props;
                            return (<InfoCard {...newProps} title={i18n.t(title)} data={dataWithParents}/>)
                        }}</Query>
                )
                }
            </React.Fragment>

        )
    }
}

export default withApollo(withRouter(QueryInfoCard));
